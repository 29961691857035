import { DownOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Collapse, Dropdown, InputNumber, Menu, Table } from 'antd';
import recruitApi from 'api/recruitApi';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  DatePickerField,
  InputField,
  RangeTimePickerField,
  RichTextField,
  SelectField,
} from 'components/FormFields';
import ITVSelect from 'components/ITVSelect';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import {
  getDetailCandidateInJob,
  getListUserApproverOffer,
  notifyInterviewer,
  sendMailApproverOffer,
  sendMailOffer,
} from 'features/recruitment/recruitmentAction';
import { actions, selectRecruitmentData } from 'features/recruitment/recruitmentSlice';
import { useEmployeeListForContractV2 } from 'hooks/fetchers/useEmployeeListForContractV2';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { getErrorMessage } from 'utils/getErrorMessage';
import { removeAccents } from 'utils/text';
import * as Yup from 'yup';

export enum TypeNotifiInterviewer {
  RECRUIT_ATTEND_INTERVIEW = 'Attend interview',
  RECRUIT_EVALUATE_CANDIDATE = 'Evaluate candidate',
}

enum HiringProcessStatusCandidate {
  APPLICATION_RECEIVED = 'Application Received',
  SHORTLISTED = 'Shortlisted',
  IN_PROGRESS = 'In Progress',
  JOB_OFFER = 'Job Offer',
  OFFER_ACCEPTED = 'Offer Accepted',
  OFFER_REJECTED = 'Offer Rejected',
  CLOSED = 'Closed',
}

const { Panel } = Collapse;

const UserForm = ({ currentUser, onCancel }: { currentUser: any; onCancel?: () => void }) => {
  const { t } = useTranslation(['recruitment', 'timesheet']);

  const validationSchemaInterview = Yup.object().shape({
    interviewTime: Yup.array()
      .of(Yup.string().required('Both start and end times are required'))
      .required('Interview Time is required')
      .min(2, 'Both start and end times are required'),
    interviewDateAndTime: Yup.date().required('Interview Date is required'),
    interviewLocation: Yup.string().required('Interview Location is required'),
  });
  const validationSchemaJobOffer = Yup.object().shape({
    offerApprover: Yup.array()
      .required('Approver Offer is required')
      .min(1, 'Approver Offer is required'),
    expectedStartDate: Yup.date().required('Expected Start Date is required'),
    salaryOffer: Yup.number().required('Salary Offered is required'),
  });

  const dispatch = useAppDispatch();
  const filters = useMemo(() => ({ offset: 1, limit: 1000 }), []);
  const { data } = useEmployeeListForContractV2(filters);
  const [specificEmployee, setSpecificEmployee] = useState<any>([]);
  const [selectedApprover, setSelectedApprover] = useState<any>([]);
  const [isReload, setIsReload] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const { resetcandidateManagement } = actions;

  const detailCandidateInJob = useAppSelector(selectRecruitmentData)?.detailCandidateInJob;
  const listUserApproverOffer = useAppSelector(selectRecruitmentData)?.listUserApproverOffer;

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: [
      HiringProcessStatusCandidate.JOB_OFFER,
      HiringProcessStatusCandidate.OFFER_ACCEPTED,
      HiringProcessStatusCandidate.OFFER_REJECTED,
    ]?.includes(detailCandidateInJob?.statusCandidate)
      ? yupResolver(validationSchemaJobOffer)
      : detailCandidateInJob?.type === 'Interview'
      ? yupResolver(validationSchemaInterview)
      : undefined,
  });

  const formWatch = watch();

  useEffect(() => {
    if (detailCandidateInJob?.type === 'Interview') {
      setIsFormValid(
        formWatch?.interviewDateAndTime && formWatch?.interviewTime && formWatch?.interviewLocation,
      );
    }
  }, [formWatch]);

  useEffect(() => {
    if (
      [
        HiringProcessStatusCandidate.JOB_OFFER,
        HiringProcessStatusCandidate.OFFER_ACCEPTED,
        HiringProcessStatusCandidate.OFFER_REJECTED,
      ]?.includes(detailCandidateInJob?.statusCandidate)
    ) {
      dispatch(getListUserApproverOffer({}));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailCandidateInJob?.statusCandidate]);

  useEffect(() => {
    setSelectedApprover(detailCandidateInJob?.offerApprover?.map((i) => i?.userId) || []);
  }, [detailCandidateInJob?.offerApprover]);

  useEffect(() => {
    setSpecificEmployee(detailCandidateInJob?.interviewers?.map((i) => i?.userId) || []);
  }, [detailCandidateInJob?.interviewers]);

  useEffect(() => {
    if (detailCandidateInJob) {
      const interviewDateAndTime = detailCandidateInJob.interviewDateAndTime
        ? moment(detailCandidateInJob.interviewDateAndTime)
        : null;
      const startTime =
        detailCandidateInJob.interviewTime?.length > 1
          ? detailCandidateInJob.interviewTime[0]
          : null;
      const endTime =
        detailCandidateInJob.interviewTime?.length > 1
          ? detailCandidateInJob.interviewTime[1]
          : null;

      setValue(
        'expectedStartDate',
        detailCandidateInJob.expectedStartDate
          ? moment(detailCandidateInJob.expectedStartDate)
          : null,
      );
      setValue('salaryOffer', detailCandidateInJob.salaryOffer);
      setValue('currencySymbol', detailCandidateInJob.currencySymbol || 'VND');
      setValue('offerStatus', detailCandidateInJob.offerStatus);
      setValue('notes', detailCandidateInJob.notes);
      setValue(
        'interviewers',
        detailCandidateInJob.interviewers?.map((i) => i.userId),
      );
      setValue('interviewDateAndTime', interviewDateAndTime);
      if (interviewDateAndTime && startTime && endTime) {
        setValue('interviewTime', [
          interviewDateAndTime.clone().set({
            hour: moment(startTime, 'HH:mm:ss').hour(),
            minute: moment(startTime, 'HH:mm:ss').minute(),
            second: moment(startTime, 'HH:mm:ss').second(),
          }),
          interviewDateAndTime.clone().set({
            hour: moment(endTime, 'HH:mm:ss').hour(),
            minute: moment(endTime, 'HH:mm:ss').minute(),
            second: moment(endTime, 'HH:mm:ss').second(),
          }),
        ]);
      } else {
        setValue('interviewTime', null);
      }
      setValue('interviewLocation', detailCandidateInJob.interviewLocation);
      setValue('result', detailCandidateInJob.result);
      setValue('testType', detailCandidateInJob.testType);
      setValue(
        'offerApprover',
        detailCandidateInJob.offerApprover?.map((i) => i.userId),
      );
    }
  }, [detailCandidateInJob, setValue]);

  const handleChangeEmployee = (value: any, key: any) => {
    let is_obj = [];
    for (let i = 0; i < value.length; i++) {
      is_obj.push(key[i].key);
    }
    setSpecificEmployee(is_obj);
  };

  const handleChangeApprover = (value: any, key: any) => {
    let is_obj = [];
    for (let i = 0; i < value.length; i++) {
      is_obj.push(key[i].key);
    }
    setSelectedApprover(is_obj);
  };

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(getDetailCandidateInJob({ id: currentUser.id }));
    }
    return () => {
      dispatch(resetcandidateManagement({}));
    };
  }, [currentUser, dispatch, resetcandidateManagement, isReload]);

  const onSubmit = async (dataForm: any) => {
    const body = {
      ...dataForm,
      interviewDateAndTime: dataForm?.interviewDateAndTime
        ? moment(dataForm.interviewDateAndTime).format('YYYY-MM-DD')
        : dataForm?.interviewDateAndTime,
      interviewTime:
        dataForm?.interviewTime?.length > 1
          ? [
              moment(dataForm?.interviewTime[0]).format('HH:mm:ss'),
              moment(dataForm?.interviewTime[1]).format('HH:mm:ss'),
            ]
          : dataForm?.interviewTime,
      salaryOffer: dataForm?.salaryOffer,
      interviewers: data
        ?.filter((item) => specificEmployee?.some((e) => e === item?.id))
        ?.map((i) => ({ userId: i?.id, fullName: i?.fullName, email: i?.email })),
      offerApprover: listUserApproverOffer
        ?.filter((item: any) => selectedApprover?.some((e: any) => e === item?.id))
        ?.map((i: any) => ({ userId: i?.id, fullName: i?.fullName, email: i?.email })),
    };
    try {
      dispatch(setGlobalLoading({ loading: true }));
      const response = await recruitApi.updateDetailCandidateInJob({
        id: detailCandidateInJob?.id,
        body,
      });
      if (response?.data?.offerStatus) {
        setValue('offerStatus', response.data.offerStatus);
      }
      dispatch(setGlobalLoading({ loading: false }));
    } catch (error) {
      dispatch(setGlobalLoading({ loading: false }));
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }

    // dispatch(updateDetailCandidateInJob({ id: detailCandidateInJob?.id, body }));
  };

  const renderForm = () => {
    if (
      [
        HiringProcessStatusCandidate.JOB_OFFER,
        HiringProcessStatusCandidate.OFFER_ACCEPTED,
        HiringProcessStatusCandidate.OFFER_REJECTED,
      ]?.includes(detailCandidateInJob?.statusCandidate)
    ) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <div>
            <div style={{ fontWeight: 500, margin: '8px 0' }}>
              {t('recruitment:approver_offer')} <span style={{ color: 'red' }}>*</span>
            </div>
            <Controller
              name="offerApprover"
              control={control}
              render={({ field }) => (
                <ITVSelect
                  {...field}
                  dataSource={listUserApproverOffer}
                  valueSelected={selectedApprover}
                  mode="multiple"
                  width={452}
                  size="middle"
                  onChangeSelect={(value, key) => {
                    field.onChange(value); // Cập nhật giá trị khi chọn
                    handleChangeApprover(value, key);
                  }}
                  filterOption={(input, option) =>
                    removeAccents(String(option?.label).toLowerCase()).indexOf(
                      removeAccents(input.toLowerCase()),
                    ) >= 0
                  }
                  statusError={errors.offerApprover ? true : false}
                />
              )}
            />
            {errors.offerApprover && (
              <StyledErrorMessage>{errors.offerApprover.message}</StyledErrorMessage>
            )}
          </div>

          <DatePickerField
            style={{ width: '100%' }}
            control={control}
            name="expectedStartDate"
            label={
              <span>
                {t('recruitment:expected_start_date')} <span style={{ color: 'red' }}>*</span>
              </span>
            }
            rules={{ required: 'Expected Start Date is required' }}
          />
          <div style={{ marginBottom: 8 }}>
            <label>
              {t('recruitment:salary_offered')} <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Controller
                name="salaryOffer"
                control={control}
                rules={{ required: 'Salary Offered is required' }}
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    style={{ width: '100%' }}
                    min={0}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    status={errors?.salaryOffer?.message ? 'error' : undefined}
                  />
                )}
              />
              <SelectField
                style={{ width: 100 }}
                control={control}
                options={[
                  { label: 'USD', value: 'USD' },
                  { label: 'VND', value: 'VND' },
                ]}
                name="currencySymbol"
              />
            </div>

            {errors?.salaryOffer?.message && (
              <StyledErrorMessage>{errors?.salaryOffer?.message}</StyledErrorMessage>
            )}
          </div>
          <InputField
            control={control}
            name="offerStatus"
            label={t('recruitment:offer_status')}
            disabled
          />
          <RichTextField control={control} name="notes" label={t('recruitment:notes')} />
        </div>
      );
    } else if (detailCandidateInJob?.type === 'Interview') {
      const evaluationData = detailCandidateInJob?.evaluation || [];
      const dataWithSummary = evaluationData;

      return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <div>
            <div style={{ fontWeight: 500, margin: '8px 0' }}>{t('recruitment:interviewers')}</div>
            <Controller
              name="interviewers"
              control={control}
              render={({ field }) => (
                <ITVSelect
                  {...field}
                  dataSource={data}
                  valueSelected={specificEmployee}
                  mode="multiple"
                  width={552}
                  size="middle"
                  onChangeSelect={handleChangeEmployee}
                  filterOption={(input, option) =>
                    removeAccents(String(option?.label).toLowerCase()).indexOf(
                      removeAccents(input.toLowerCase()),
                    ) >= 0
                  }
                />
              )}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '49%' }}>
              <DatePickerField
                style={{ width: '100%' }}
                control={control}
                name="interviewDateAndTime"
                label={
                  <span>
                    {t('recruitment:interview_date')} <span style={{ color: 'red' }}>*</span>
                  </span>
                }
              />
            </div>
            <div style={{ width: '49%' }}>
              <RangeTimePickerField
                style={{ width: '100%' }}
                control={control}
                name="interviewTime"
                label={
                  <span>
                    {t('recruitment:interview_time')} <span style={{ color: 'red' }}>*</span>
                  </span>
                }
              />
            </div>
          </div>
          <InputField
            control={control}
            name="interviewLocation"
            label={
              <span>
                {t('recruitment:interview_location')} <span style={{ color: 'red' }}>*</span>
              </span>
            }
          />
          <SelectField
            control={control}
            name="result"
            label={t('recruitment:interview_result')}
            options={[
              { value: 'Pass', label: 'Pass' },
              { value: 'Fail', label: 'Fail' },
            ]}
          />
          <Collapse>
            {dataWithSummary.map((item, index) => (
              <Panel header={item.evaluatedByName} key={index}>
                <Table
                  dataSource={item.data}
                  columns={[
                    {
                      title: t('recruitment:competency_name') + '',
                      dataIndex: 'name',
                      key: 'name',
                    },
                    {
                      title: t('recruitment:score') + '',
                      dataIndex: 'score',
                      key: 'score',
                      render: (_, record) => `${record.score}/${record.maximumScore}`,
                    },
                    { title: t('recruitment:notes') + '', dataIndex: 'comment', key: 'comment' },
                  ]}
                  pagination={false}
                />
                <div style={{ marginTop: 16, fontSize: 16 }}>
                  <strong style={{ fontSize: 18 }}>{t('recruitment:overall_score')}: </strong>
                  <strong>{item.overallScore}</strong>
                </div>
              </Panel>
            ))}
          </Collapse>
        </div>
      );
    } else if (detailCandidateInJob?.type === 'Test') {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <SelectField
            control={control}
            name="testType"
            label={t('recruitment:type_of_test')}
            options={[{ value: 'Job knowledge test', label: 'Job knowledge test' }]}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '49%' }}>
              <DatePickerField
                style={{ width: '100%' }}
                control={control}
                name="interviewDateAndTime"
                label={t('recruitment:test_date')}
              />
            </div>
            <div style={{ width: '49%' }}>
              <RangeTimePickerField
                style={{ width: '100%' }}
                control={control}
                name="interviewTime"
                label={t('recruitment:test_time')}
              />
            </div>
          </div>
          <SelectField
            control={control}
            name="result"
            label={t('recruitment:test_result')}
            options={[
              { value: 'Pass', label: 'Pass' },
              { value: 'Fail', label: 'Fail' },
            ]}
          />
          <RichTextField control={control} name="notes" label={t('recruitment:notes')} />
        </div>
      );
    }
    return null;
  };

  const handleMenuClick = async (actionType?: string) => {
    if (actionType) {
      try {
        await handleSubmit(async (dataForm) => {
          await onSubmit(dataForm);

          dispatch(
            notifyInterviewer({
              id: currentUser?.id,
              body: { type: actionType },
            }),
          );
        })();
      } catch (error) {
        console.error('Error in submitting form:', error);
      }
    } else {
      try {
        await handleSubmit(async (dataForm) => {
          await onSubmit(dataForm);

          await dispatch(
            sendMailApproverOffer({
              body: {
                jobCandidateIds: [currentUser?.candidateRequisitionId],
                offerApprover: listUserApproverOffer
                  ?.filter((item: any) => selectedApprover?.some((e: any) => e === item?.id))
                  ?.map((i: any) => ({ userId: i?.id, fullName: i?.fullName, email: i?.email })),
                status: 'approved',
                offerStatus: getValues('offerStatus'),
                expectedStartDate: getValues('expectedStartDate'),
                salaryOffer: getValues('salaryOffer'),
                currencySymbol: getValues('currencySymbol'),
                notes: getValues('notes'),
                candidateProcessId: detailCandidateInJob?.id,
              },
              callBack: () => {
                dispatch(getDetailCandidateInJob({ id: currentUser.id }));
              },
            }),
          );
          await dispatch(resetcandidateManagement({}));
          setIsReload(true);
        })();
      } catch (error) {
        console.error('Error in submitting form:', error);
      }
    }
  };

  const buttonMenu = (
    <Menu>
      <Menu.Item onClick={() => handleMenuClick(TypeNotifiInterviewer.RECRUIT_ATTEND_INTERVIEW)}>
        {t('recruitment:request_attend_interview')}
      </Menu.Item>
      <Menu.Item onClick={() => handleMenuClick(TypeNotifiInterviewer.RECRUIT_EVALUATE_CANDIDATE)}>
        {t('recruitment:request_evaluate_candidate')}
      </Menu.Item>
    </Menu>
  );

  const menuItems = [
    {
      key: '1',
      label: t('recruitment:interview_invitation'),
      onClick: () => {
        handleSubmit(async (dataForm) => {
          await onSubmit(dataForm);

          dispatch(
            sendMailOffer({
              body: {
                jobCandidateIds: [currentUser?.candidateRequisitionId],
                status: 'approved',
                type: 'Interview',
              },
            }),
          );
        })();
      },
    },
    {
      key: '2',
      label: t('recruitment:interview_rejection'),
      onClick: () => {
        handleSubmit(async (dataForm) => {
          await onSubmit(dataForm);

          dispatch(
            sendMailOffer({
              body: {
                jobCandidateIds: [currentUser?.candidateRequisitionId],
                status: 'rejected',
                type: 'Interview',
              },
            }),
          );
        })();
      },
    },
  ];

  const renderButton = () => {
    if (detailCandidateInJob?.statusCandidate === 'Job Offer') {
      return (
        <CustomButton onClick={() => handleMenuClick()}>
          {t('recruitment:send_for_approval')}
        </CustomButton>
      );
    } else if (detailCandidateInJob?.type === 'Interview') {
      return (
        <>
          <Dropdown disabled={!isFormValid} overlay={buttonMenu}>
            <CustomButton style={{ marginLeft: 5 }}>
              {t('recruitment:notify_interviewer')} <DownOutlined />
            </CustomButton>
          </Dropdown>
          <Dropdown disabled={!isFormValid} menu={{ items: menuItems }}>
            <CustomButton aType="primary">
              {t('recruitment:send_interview_email')} <DownOutlined />
            </CustomButton>
          </Dropdown>
        </>
      );
    }
    return null;
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {renderForm()}
      {!!renderForm() && (
        <div style={{ display: 'flex', justifyContent: 'center', gap: 8, marginTop: '1rem' }}>
          <CustomButton
            aType="text"
            onClick={() => {
              handleCancel();
            }}
          >
            {t('timesheet:Cancel')}
          </CustomButton>
          {detailCandidateInJob?.offerStatus !== 'Waiting for approval' && (
            <CustomButton htmlType="submit">{t('timesheet:Save')}</CustomButton>
          )}
          {renderButton()}
        </div>
      )}
    </form>
  );
};

export default UserForm;

const StyledErrorMessage = styled.div`
  color: var(--cl_error500);
  margin-top: 6px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;
