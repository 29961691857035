import React, { useEffect, useState } from 'react';
import { Form, Input, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DatePickerIcon } from 'assets/icons';
import CustomSelect from 'styles/inputSelectStyled';
import CustomButton from 'styles/buttonStyled';
import moment from 'moment';
import { YYYY_MM_DD } from 'constants/commons';
import payrollDashboardApi from 'api/payrollDashboardApi';

const { Option } = Select;
enum JobStatus {
  NEW = 'New',
  WAITING_FOR_APPROVAL = 'Waiting for approval',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CANCELED = 'Canceled',
}

const JobSimpleFilter = ({ onFilterChange }: { onFilterChange: (filter: any) => void }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['recruitment', 'action', 'timesheet', 'employee']);
  const [location, setLocation] = useState([]);
  const [department, setDepartment] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const responseDataLocation = await payrollDashboardApi.getLocation();
      setLocation(responseDataLocation?.data?.result || []);
      const responseDataProject = await payrollDashboardApi.getProject();
      setDepartment(responseDataProject?.data?.result || []);
    };
    fetchData();
  }, []);

  const onApply = () => {
    form.validateFields().then((values) => {
      const filter = {
        ...values,
        createdAt: values.createdAt ? moment(values.createdAt).format(YYYY_MM_DD) : undefined,
      };
      onFilterChange(filter);
    });
  };

  const onClear = () => {
    form.resetFields();
  };

  return (
    <Form layout="vertical" form={form}>
      <ContainerStyled>
        <WrapperStyled>
          <TitleStyled>{t('action:filter')}</TitleStyled>
          <Form.Item name="positionName" label={t('recruitment:position_name')}>
            <Input />
          </Form.Item>
          <Form.Item name="jobDepartment" label={t('recruitment:job_department')}>
            <CustomSelect mode="multiple" allowClear>
              {department.map((i: any) => (
                <Option key={i.projectCode} value={i.projectCode}>
                  {i.name}
                </Option>
              ))}
            </CustomSelect>
          </Form.Item>
          <Form.Item name="jobLocation" label={t('recruitment:job_location')}>
            <CustomSelect mode="multiple" allowClear>
              {location.map((i: any) => (
                <Option key={i.id} value={i.id + ''}>
                  {i.name}
                </Option>
              ))}
            </CustomSelect>
          </Form.Item>
          <Form.Item name="headcount" label={t('recruitment:headcount')}>
            <Input type="number" />
          </Form.Item>
          <Form.Item name="totalApplications" label={t('recruitment:total_applications')}>
            <Input type="number" />
          </Form.Item>
          <Form.Item name="status" label={t('recruitment:job_status')}>
            <CustomSelect allowClear>
              <Option value="New">{JobStatus.NEW}</Option>
              <Option value="Waiting for approval">{JobStatus.WAITING_FOR_APPROVAL}</Option>
              <Option value="Approved">{JobStatus.APPROVED}</Option>
              <Option value="Rejected">{JobStatus.REJECTED}</Option>
              <Option value="Canceled">{JobStatus.CANCELED}</Option>
            </CustomSelect>
          </Form.Item>
          <Form.Item name="createdBy" label={t('recruitment:created_by')}>
            <Input />
          </Form.Item>
          <Form.Item name="createdReason" label={t('recruitment:reason_by')}>
            <Input />
          </Form.Item>
          <Form.Item name="actionBy" label={t('recruitment:approved_by')}>
            <Input />
          </Form.Item>
          <Form.Item name="createdAt" label={t('recruitment:job_created_date')}>
            <DatePicker style={{ width: '100%' }} suffixIcon={<DatePickerIcon />} />
          </Form.Item>
          <FooterStyled>
            <CustomButton aType="outline" onClick={onClear}>
              {t('employee:filter.clear')}
            </CustomButton>
            <CustomButton onClick={onApply}>{t('action:apply')}</CustomButton>
          </FooterStyled>
        </WrapperStyled>
      </ContainerStyled>
    </Form>
  );
};

export default JobSimpleFilter;

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 23rem;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: white;
  padding: 1rem 10px 0 1rem;
  box-shadow: 0px 10px 18px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  max-height: 500px;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    background: white;
  }
`;

const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 10;
  padding: 10px 0;
`;

const TitleStyled = styled.div`
  font-size: var(--txt_font_size_h3);
  font-weight: var(--txt_font_weight_medium);
  padding-bottom: 10px;
  text-transform: capitalize;
`;
