import React, { useMemo } from 'react';
import { Form, Input, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DatePickerIcon } from 'assets/icons';
import CustomSelect from 'styles/inputSelectStyled';
import CustomButton from 'styles/buttonStyled';
import moment from 'moment';
import { YYYY_MM_DD } from 'constants/commons';

const { Option } = Select;

enum HiringProcessStatusCandidate {
  APPLICATION_RECEIVED = 'Application Received',
  SHORTLISTED = 'Shortlisted',
  IN_PROGRESS = 'In Progress',
  JOB_OFFER = 'Job Offer',
  OFFER_ACCEPTED = 'Offer Accepted',
  OFFER_REJECTED = 'Offer Rejected',
  CLOSED = 'Closed',
}

enum Status {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  WAITING_FOR_APPROVAL = 'Waiting for approval',
}

const CandidateSimpleFilter = ({ onFilterChange, filter, status }: any) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['recruitment', 'action', 'timesheet', 'employee']);

  //if filter is empty object, reset form
  useMemo(() => {
    if (Object?.keys(filter)?.length === 0) {
      form.resetFields();
    }
  }, [form, filter]);

  const onApply = () => {
    form.validateFields().then((values) => {
      const filter = {
        ...values,
        appliedDate: values.appliedDate ? moment(values.appliedDate).format(YYYY_MM_DD) : undefined,
        expectedStartDate: values.expectedStartDate
          ? moment(values.expectedStartDate).format(YYYY_MM_DD)
          : undefined,
        createdAt: values.createdAt ? moment(values.createdAt).format(YYYY_MM_DD) : undefined,
        interviewDateAndTime: values?.interviewDate
          ? moment(values.interviewDate).format(YYYY_MM_DD)
          : undefined,
      };
      filter.interviewDate = undefined;
      onFilterChange && onFilterChange(filter);
    });
  };

  const onClear = () => {
    form.resetFields();
  };

  return (
    <Form layout="vertical" form={form}>
      <ContainerStyled>
        <WrapperStyled>
          <TitleStyled>{t('action:filter')}</TitleStyled>
          <Form.Item name="candidateName" label={t('recruitment:candidate_name')}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label={t('recruitment:email')}>
            <Input />
          </Form.Item>
          <Form.Item name="phone" label={t('recruitment:phone_number')}>
            <Input />
          </Form.Item>
          <Form.Item name="positionName" label={t('recruitment:applied_position')}>
            <Input />
          </Form.Item>
          <Form.Item name="appliedDate" label={t('recruitment:applied_date')}>
            <DatePicker style={{ width: '100%' }} suffixIcon={<DatePickerIcon />} />
          </Form.Item>
          {(status === HiringProcessStatusCandidate.IN_PROGRESS || !status) && (
            <Form.Item name="stageName" label={t('recruitment:stage_name')}>
              <Input />
            </Form.Item>
          )}
          {HiringProcessStatusCandidate.IN_PROGRESS === status && (
            <>
              <Form.Item name="interviewDate" label={t('recruitment:interview_date')}>
                <DatePicker style={{ width: '100%' }} suffixIcon={<DatePickerIcon />} />
              </Form.Item>
              <Form.Item name="location" label={t('recruitment:location')}>
                <Input />
              </Form.Item>
              <Form.Item name="interviewerStatus" label={t('recruitment:interviewerStatus')}>
                <CustomSelect allowClear>
                  {[Status.APPROVED, Status.REJECTED].map((status) => (
                    <Option key={status} value={status}>
                      {status}
                    </Option>
                  ))}
                </CustomSelect>
              </Form.Item>
              <Form.Item
                name="candidateInterviewStatus"
                label={t('recruitment:candidateInterviewStatus')}
              >
                <CustomSelect allowClear>
                  {[Status.APPROVED, Status.REJECTED].map((status) => (
                    <Option key={status} value={status}>
                      {status}
                    </Option>
                  ))}
                </CustomSelect>
              </Form.Item>
            </>
          )}
          {[
            HiringProcessStatusCandidate.JOB_OFFER,
            HiringProcessStatusCandidate.OFFER_ACCEPTED,
            HiringProcessStatusCandidate.OFFER_REJECTED,
          ].some((stt) => stt === status) && (
            <>
              <Form.Item name="expectedStartDate" label={t('recruitment:expected_start_date')}>
                <DatePicker style={{ width: '100%' }} suffixIcon={<DatePickerIcon />} />
              </Form.Item>
              <Form.Item name="salaryOffer" label={t('recruitment:salary_offer')}>
                <Input type="number" />
              </Form.Item>
              <Form.Item name="offerStatus" label={t('timesheet:status')}>
                <CustomSelect allowClear>
                  {Object.values(Status).map((status) => (
                    <Option key={status} value={status}>
                      {status}
                    </Option>
                  ))}
                </CustomSelect>
              </Form.Item>
            </>
          )}
          {status === HiringProcessStatusCandidate.OFFER_REJECTED && (
            <Form.Item name="offerBy" label={t('recruitment:rejected_by')}>
              <Input />
            </Form.Item>
          )}
          <Form.Item name="createdAt" label={t('recruitment:created_date')}>
            <DatePicker style={{ width: '100%' }} suffixIcon={<DatePickerIcon />} />
          </Form.Item>
          <FooterStyled>
            <CustomButton aType="outline" onClick={onClear}>
              {t('employee:filter.clear')}
            </CustomButton>
            <CustomButton onClick={onApply}>{t('action:apply')}</CustomButton>
          </FooterStyled>
        </WrapperStyled>
      </ContainerStyled>
    </Form>
  );
};

export default CandidateSimpleFilter;

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 23rem;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: white;
  padding: 1rem 10px 0 1rem;
  box-shadow: 0px 10px 18px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  max-height: 500px;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    background: white;
  }
`;

const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 10;
  padding: 10px 0;
`;

const TitleStyled = styled.div`
  font-size: var(--txt_font_size_h3);
  font-weight: var(--txt_font_weight_medium);
  padding-bottom: 10px;
  text-transform: capitalize;
`;
